<template>
  <div>
    <v-card class="rounded-lg" elevation="0">
      <section class="header-table">
        <v-text-field
          v-model="search"
          clearable
          class="rounded-lg"
          append-icon="mdi-magnify"
          label="Macrorregião..."
          single-line
          hide-details
          outlined
          dense
          @keyup.enter="searchRegister"
        ></v-text-field>
        <div class="space"></div>
        <v-select
          dense
          outlined
          clearable
          v-model="state_search"
          :items="StateList"          
          item-text="name"
          item-value="id"
          label="Estado"
        ></v-select>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="success"
          @click="searchRegister"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-magnify </v-icon>
          <span class="textBtn"> Pesquisar </span>
        </v-btn>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="primary"
          @click="dialog = true"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-plus </v-icon>
          <span class="textBtn"> Novo </span>
        </v-btn>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="#C62828"
          @click="clearSearch"
        >
          <span class="textBtn"> Limpar </span>
        </v-btn>
      </section>

      <v-data-table
        :headers="headers"
        :items="regionalNucleusHealth"        
        :footer-props="{
          'items-per-page-text': 'Registros por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :loading="loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <Crud :resource="item" v-on:eventname="crudUser" />
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" width="600px" persistent>
        <v-card class="create-user-dialog rounded-lg">
          <div class="banner">
            <h4>Criar Macrorregião</h4>
          </div>

          <AlertError
            class="mb-5"
            v-model="displayError"
            :resource="displayError"
          />

          <v-form @submit.prevent="create" ref="form">
            <v-text-field
              class="rounded-lg"
              label="Nome da macrorregião"
              placeholder="Macrorregião"
              outlined
              v-model="name"
              :rules="nameRules"
              dense
            ></v-text-field>

            <v-select
              dense
              outlined
              v-model="State"
              :items="StateList"
              :rules="nameRules"
              item-text="name"
              item-value="id"
              label="Estado"
            ></v-select>

            <v-card-actions class="cardFooter">
              <v-spacer></v-spacer>

              <v-btn
                class="rounded-lg"
                color="#e74c3c"
                text
                elevation="0"
                @click="dialog = false"
              >
                <span>Cancelar</span>
              </v-btn>

              <v-btn
                class="rounded-lg"
                elevation="0"
                color="#27ae60"
                type="submit"
              >
                <span class="textBtn">Enviar dados</span>
                <v-icon color="#FFFFFF" small class="ml-2"
                  >mdi-arrow-right</v-icon
                >
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import Crud from "./CrudRegionalNucleusHealth-comp.vue";
import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      search1: "",
      headers: [
        { text: "Ações", value: "actions", sortable: false, width: "20%" },
        {
          text: "Macrorregião",
          align: "start",
          value: "name",
          sortable: false,
        },
        {
          text: "Estado",
          align: "start",
          value: "state.name",
          sortable: false,
        },
      ],

      nameRules: [(v) => !!v || "O campo é obrigatório"],

      displayError: {
        display: false,
        message: [],
      },

      page: 2,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},
      sortBy: "name",

      regionalNucleusHealth: [],
      totalregionalNucleusHealth: 0,

      message: "",
      snackbar: false,
      loadingBTN: false,
      exibirMensagem: false,
      messageError: "",

      name: "",
      State: "",
      StateList: [],
    };
  },

  components: {
    Crud,
    AlertError,
  },

  watch: {
    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },
    options: {
      handler() {
        this.indoDataTable();
      },
    },
    deep: true,
  },

  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    loadSelects() {
      axios.get(`/state`).then((response) => {
        this.StateList = response.data;
      });
    },

    clearSearch() {
      this.search = "";
      this.state_search = "";
      this.indoDataTable()
    },
    searchRegister() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;

      axios
        .get(
          `/regional-nucleus-health?page=${pageNumber}&per_page=${10}&name=${
            this.search
          }&state_id=${this.state_search}`
        )
        .then((response) => {
          this.loading = false;
          this.regionalNucleusHealth = response.data.data;
          this.totalregionalNucleusHealth = response.data.total;
          this.numberOfPages = response.data.last_page;
        });
    },
    switchStatus() {
      if (this.switchRegionalNucleus) return;
    },
    indoDataTable() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;

      axios
        .get(
          `/regional-nucleus-health?page=${pageNumber}&per_page=${10}&name=${
            this.search
          }&state_id=${this.state_search}`
        )
        .then((response) => {
          this.loading = false;
          this.regionalNucleusHealth = response.data.data;
          this.totalregionalNucleusHealth = response.data.total;
          this.numberOfPages = response.data.last_page;
        });
    },

    create() {
      if (this.$refs.form.validate() == true) {
        const dados = new Object();
        dados.name = this.name;
        dados.state_id = this.State;

        axios
          .post(`/regional-nucleus-health`, dados)
          .then((response) => {
            this.indoDataTable();
            this.resetForm();
            this.message = response.data.message;
            this.snackbar = true;
            this.dialog = false;
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      }
    },

    crudUser(resource) {
      if (resource != "") {
        axios
          .delete(`/regional-nucleus-health/${resource}`)
          .then((response) => {
            this.indoDataTable();
            this.message = response.data.message;
            this.snackbar = true;
          });
        return;
      }

      this.indoDataTable();
    },
  },

  mounted() {
    this.indoDataTable();
    this.loadSelects();
    this.State = 5;
  },
};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #27ae60, #1abc9c);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
